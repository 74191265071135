<template>
  <v-row class="ma-2" justify="center">
    <v-col cols="12">
      <v-card
          class="mx-auto text--white"
          color="#fafafa"
          max-width="700"
      >
        <v-card-title>
          <p class="text-h4 text--primary">
            Welcome to Web Express Media
          </p>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          "Webexpressmedia: Web Express Media is an online advertising and link building agency that has been providing its services to businesses for over 2 years. The company has a wealth of experience and knowledge when it comes to digital advertising and link building, and they've put that expertise to use in managing some of the largest media and news sites on the worldwide, including BioOverview and many others. With over 20 million readers a month combined, the company has built a strong reputation as a leading provider of link building services."
        </v-card-text>

        <v-card-actions>
          <v-list-item class="grow">
            <v-row
                align="center"
                justify="end"
            >
              <social-icon v-for="(item, key) in socialData" :link="item.link" :icon="item.icon" :key="key"/>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SocialIcon from "../components/buttons/SocialIcon";
export default {
  name: "Home",
  components: {SocialIcon},
  data() {
    return {
      socialData: [
        {
          icon: 'mdi-facebook',
          link: '#'
        },
        {
          icon: 'mdi-youtube',
          link: '#'
        },
        {
          icon: 'mdi-linkedin',
          link: '#'
        },
        {
          icon: 'mdi-twitter',
          link: '#'
        }
      ]
    }
  }
}
</script>

